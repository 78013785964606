import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { FuseSharedModule } from '@fuse/shared.module';

import { SampleComponent } from './sample.component';
import { UserResolver } from 'app/auth-core/user.resolver';
import { AuthService } from 'app/auth-core/auth.service';
import { UserService } from 'app/auth-core/user.service';
import { AuthGuard } from 'app/auth-core/auth.guard';

const routes = [
    {
        path     : 'home',
        component: SampleComponent,
        resolve: { data: UserResolver}
    }
];

@NgModule({
    declarations: [
        SampleComponent
    ],
    imports     : [
        RouterModule.forChild(routes),

        TranslateModule,

        FuseSharedModule
    ],
    providers: [AuthService, UserService, UserResolver, AuthGuard],
    exports     : [
        SampleComponent
    ]
})

export class SampleModule
{
}
