import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import { FirebaseUserModel } from './user.model';

@Injectable()
export class UserService {

    constructor(
        public db: AngularFirestore,
        public afAuth: AngularFireAuth
    ) {
    }


    getCurrentUser(): Promise<FirebaseUserModel> {
        const user = new FirebaseUserModel();

        return new Promise<any>((resolve, reject) => {
            firebase.auth().onAuthStateChanged(
                (res: any) => {
                    if (res) {
                        if (res.providerData[0].providerId === 'password') {
                            user.image = 'https://via.placeholder.com/400x300';
                            user.name = res.displayName || res.email;
                            user.provider = res.providerData[0].providerId;
                        } else {
                            user.image = res.photoURL;
                            user.name = res.displayName || res.email;
                            user.provider = res.providerData[0].providerId;
                        }

                        resolve(user);
                    } else {
                        reject('No user logged in');
                    }
                }
            );
        });
    }

    updateCurrentUser(value): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            const user = firebase.auth().currentUser;
            user.updateProfile({
                displayName: value.name,
                photoURL: user.photoURL
            }).then(res => {
                resolve(res);
            }, err => reject(err));
        });
    }
}
