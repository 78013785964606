import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { 
    MatButtonModule, 
    MatCheckboxModule, 
    MatFormFieldModule, 
    MatIconModule, 
    MatInputModule,
    MatSnackBarModule
} from '@angular/material';

import { FuseSharedModule } from '@fuse/shared.module';

import { LoginComponent } from './login.component';

@NgModule({
    declarations: [
        LoginComponent
    ],
    imports     : [
        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatSnackBarModule,

        FuseSharedModule
    ],
    exports: [
        LoginComponent
    ]
})
export class LoginModule
{
}
