import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { UserService } from './user.service';
import { FirebaseUserModel } from './user.model';

@Injectable()
export class UserResolver implements Resolve<FirebaseUserModel> {

    constructor(public userService: UserService, private router: Router) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<FirebaseUserModel> {

        return new Promise((resolve, reject) => {
            this.userService.getCurrentUser()
                .then((user: FirebaseUserModel) => {
                    return resolve(user);
                }, err => {
                    this.router.navigate(['/login']);
                    return reject(err);
                })
        })
    }
}
